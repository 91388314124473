// @flow
import * as React from 'react';
import { Helmet } from 'react-helmet';
import { graphql } from 'gatsby';

import Observer from 'react-intersection-observer';
import { FadeLeft, FadeUp, FadeRight, Fade } from '../Animations';
import CSForm from '../CSForm';
import Footer from '../Footer';

import { MainHeader, SubHeader } from '../Styled';
import {
  ContactPage,
  LocationImgWrapper,
  LocationImgBg,
  LocationImg,
  Wrapper,
  FlexRowWrap,
  FlexWrapper,
  InfoWrapper,
  InfoHeader,
  InfoValue,
  LocationWrapper,
  Address,
  AddressHeader,
  AddressBody,
  Pin,
} from './styles';

type State = {
  isIE: boolean,
};

export default class Contact extends React.Component<{}, State> {
  officeLocationUrl =
    'https://www.google.se/maps/@57.70266,11.9612502,17z/data=!3m1!4b1!4m5!3m4!1s0x464ff365bdd23b5d:0x57a334ae7a0dca92!8m2!3d57.70266!4d11.9634389';
  state = {
    isIE: false,
  };

  componentDidMount() {
    this.setState({
      isIE: /*@cc_on!@*/ false || !!document.documentMode,
    });
  }

  render() {
    const { isIE } = this.state;
    const { webQueryUrl } = this.props.data.site.siteMetadata;
    return (
      <React.Fragment>
        <Helmet>
          <title>Mcon | Kontakt</title>
        </Helmet>
        <ContactPage>
          <LocationImgWrapper>
            <LocationImgBg />
            <FadeUp styles={{ height: '100%' }}>
              <LocationImg />
            </FadeUp>
            <LocationWrapper>
              <a
                href={this.officeLocationUrl}
                target="_blank"
                id="test-id-map-link"
                rel="noopener noreferrer"
              >
                <FlexRowWrap>
                  <FadeRight delay={400}>
                    <Address>
                      <AddressHeader>Besök oss</AddressHeader>
                      <AddressBody>Arkivgatan 4</AddressBody>
                      <AddressBody>411 34 Göteborg</AddressBody>
                      <AddressBody>Sverige</AddressBody>
                    </Address>
                  </FadeRight>
                  <Fade delay={400}>
                    <Pin />
                  </Fade>
                </FlexRowWrap>
              </a>
            </LocationWrapper>
          </LocationImgWrapper>

          <Wrapper>
            <Observer triggerOnce>
              {({ inView, ref }) => (
                <div ref={ref}>
                  {inView && (
                    <React.Fragment>
                      <FadeLeft physics="default">
                        <MainHeader
                          colorFrom=" #082c80"
                          colorTo="#07206a"
                          isIE={isIE}
                        >
                          Redovisning på dina villkor
                        </MainHeader>
                      </FadeLeft>

                      <CSForm webQueryUrl={webQueryUrl} />

                      <FadeLeft delay={1400} xFrom={100} physics="slow">
                        <SubHeader
                          colorFrom=" #65aded"
                          colorTo="#5298e1"
                          isIE={isIE}
                        >
                          ...eller kontakta oss direkt
                        </SubHeader>
                      </FadeLeft>

                      <FadeLeft delay={1800} yFrom={20} physics="slow">
                        <FlexWrapper>
                          <InfoWrapper>
                            <InfoHeader>Email:</InfoHeader>
                            <InfoValue>info@mconab.se</InfoValue>
                          </InfoWrapper>
                          <InfoWrapper>
                            <InfoHeader>Tel:</InfoHeader>
                            <InfoValue>070 340 63 32</InfoValue>
                          </InfoWrapper>
                        </FlexWrapper>
                      </FadeLeft>
                    </React.Fragment>
                  )}
                </div>
              )}
            </Observer>
          </Wrapper>
        </ContactPage>
        <Footer
          color="#fff"
          backgroundColor={`linear-gradient(to right, #2356ac, #2356ac 15%, #4791eb 150%)`}
        />
      </React.Fragment>
    );
  }
}

export const pageQuery = graphql`
  query CSFormQuery {
    site {
      siteMetadata {
        webQueryUrl
      }
    }
  }
`;

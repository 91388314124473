// @flow
import * as React from 'react';
import { Link } from 'gatsby';
import { Keyframes, animated, config } from 'react-spring';
import { FadeRight, FadeUp } from '../Animations';
import ReactGA from 'react-ga';
import DOMPurify from 'dompurify';

import { navConfig } from '../Navigation';
import Button from '../Button';
import {
  RelativeParent,
  FormWrapper,
  CSInputField,
  CSTextArea,
  CSInputLabel,
  PrivacyTOC,
  CSCheckbox,
  CheckboxText,
  TOC,
  CSSubmitButtonWrapper,
  SuccessMessage,
  SuccessHeader,
  SuccessBody,
  FormSubmissionError,
  FieldError,
} from './styles';

// set in array so that fields maintain same order when state is updated
const formFields = ['name', 'email', 'body'];

const FormFieldsTrail = Keyframes.Trail([
  { x: 100, opacity: 0 },
  { x: 0, opacity: 1 },
]);

type Props = {};

type FormConfig = {
  fieldTitle: string,
  type: string,
  placeholder: string,
  value: string,
  isRequired: boolean,
  formObject: string,
  type: string,
  validation: Function,
  isValid: boolean,
  invalidInput: boolean,
};

type State = {
  privacyChecked: boolean,
  submissionSuccess: boolean,
  isSubmitting: boolean,
  formSubmitted: boolean,
  formIsValid: boolean,
  formConfig: {
    name: FormConfig,
    email: FormConfig,
    body: FormConfig,
  },
};

export default class CSForm extends React.Component<Props, State> {
  state = {
    privacyChecked: false,
    submissionSuccess: false,
    isSubmitting: false,
    formSubmitted: false,
    formIsValid: false,
    formConfig: {
      name: {
        fieldTitle: 'Namn',
        placeholder: 'Mitt namn är',
        value: '',
        isRequired: true,
        formObject: 'input',
        type: 'text',
        validation: (val: string) => true,
        isValid: false,
        invalidInput: false,
      },
      email: {
        fieldTitle: 'Email',
        placeholder: 'Min email är',
        value: '',
        isRequired: true,
        formObject: 'input',
        type: 'email',
        validation: (val: string) => this.emailValidator(val),
        isValid: false,
        invalidInput: false,
      },
      body: {
        fieldTitle: 'Meddelande',
        placeholder: 'Jag är intresserad av',
        value: '',
        isRequired: false,
        formObject: 'textarea',
        type: 'text',
        validation: (val: string) => true,
        isValid: true,
        invalidInput: false,
      },
    },
  };

  componentDidMount() {
    const mconSession = sessionStorage.getItem('mcon-form');
    if (!mconSession) {
      return;
    }

    const previousSession = JSON.parse(mconSession);

    Object.keys(this.state.formConfig).forEach(formItemKey => {
      const formItemState = this.state.formConfig[formItemKey];
      const session = previousSession[formItemKey];

      formItemState.value = session.value !== null ? session.value : '';
      formItemState.isValid =
        formItemState.type === 'text' ? true : session.isValid;
      formItemState.invalidInput =
        session.value.length === 0 ? false : session.invalidInput;
    });
    this.forceUpdate();
  }

  handleFormSubmit = () => {
    const { isSubmitting } = this.state;

    // safety fallback in case button was clickable when it wasn't ready or submitting
    if (isSubmitting || !this.formIsValid()) {
      return;
    }

    const formData = this.getFormData();
    const body = {
      email: formData.email,
      name: formData.name,
      body: formData.body,
    };

    this.setState(
      {
        isSubmitting: true,
      },
      async e => {
        try {
          const res = await fetch(this.props.webQueryUrl, {
            method: 'POST',
            body: JSON.stringify(body),
            headers: {
              'Content-Type': 'application/json',
            },
          });
          const json = await res.json();

          // clear sessionStorage
          sessionStorage.removeItem('mcon-form');

          // GOOGLE ANALYTICS
          ReactGA.event({
            category: 'Website Enquiry',
            action: 'Contact form',
          });

          // Show success message
          this.setState({
            submissionSuccess: true,
            isSubmitting: false,
            formSubmitted: true,
            formIsValid: false,
            privacyChecked: false,
            formConfig: {
              name: {
                ...this.state.formConfig.name,
                value: '',
                isValid: false,
                invalidInput: false,
              },
              email: {
                ...this.state.formConfig.email,
                value: '',
                isValid: false,
                invalidInput: false,
              },
              body: {
                ...this.state.formConfig.body,
                isValid: true,
                invalidInput: false,
                value: '',
              },
            },
          });
        } catch (err) {
          console.error('REQUEST FAILED', err);
          this.setState({
            isSubmitting: false,
            formSubmitted: true,
            submissionSuccess: false,
          });
        }
      }
    );
  };

  handleInputChange = (
    e: SyntheticInputEvent<HTMLInputElement | HTMLTextAreaElement>,
    key: string
  ) => {
    const { value } = e.target;
    this.setState({
      formConfig: {
        ...this.state.formConfig,
        [key]: {
          ...this.state.formConfig[key],
          value,
          invalidInput: false,
        },
      },
    });
  };

  handleInputBlur = (
    e: SyntheticInputEvent<HTMLInputElement | HTMLTextAreaElement>,
    key: string
  ) => {
    const { formConfig } = this.state;
    const formObj = formConfig[key];
    const { value } = e.target;

    const sanitizedValue = DOMPurify.sanitize(value);

    // no need to validate as text area is not required but needs to sanitize in case of malicious scripts
    if (formObj.formObject === 'textarea') {
      this.setState({
        formConfig: {
          ...this.state.formConfig,
          [key]: {
            ...this.state.formConfig[key],
            value: sanitizedValue,
          },
        },
      });
      return;
    }

    // set to invalid if no value
    if (sanitizedValue === '') {
      this.setState(
        {
          formConfig: {
            ...this.state.formConfig,
            [key]: {
              ...this.state.formConfig[key],
              value: sanitizedValue,
              isValid: false,
              invalidInput: true,
            },
          },
        },
        () => {
          sessionStorage.setItem(
            'mcon-form',
            JSON.stringify(this.state.formConfig)
          );
        }
      );
      return;
    }

    const formValueValid = formConfig[key].validation(sanitizedValue);
    // validate based on formObj type and update state accordingly
    this.setState(
      {
        formConfig: {
          ...this.state.formConfig,
          [key]: {
            ...this.state.formConfig[key],
            value: sanitizedValue,
            isValid: formValueValid,
            invalidInput: !formValueValid,
          },
        },
      },
      () => {
        sessionStorage.setItem(
          'mcon-form',
          JSON.stringify(this.state.formConfig)
        );
      }
    );
  };

  emailValidator = (val: string) => {
    const regex = /[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,3}$/i;
    return regex.test(val);
  };

  handleCheckboxClick = () => {
    this.setState(prevState => {
      return {
        privacyChecked: !prevState.privacyChecked,
      };
    });
  };

  getFormData = () => {
    const { formConfig } = this.state;
    return Object.keys(formConfig).reduce((formData, key, idx) => {
      const formObject = formConfig[key];

      formData[key] = DOMPurify.sanitize(formObject.value);
      return formData;
    }, {});
  };

  formIsValid = () => {
    const { privacyChecked, formConfig } = this.state;
    const allInputsValid = Object.keys(formConfig)
      .map(key => formConfig[key].isValid)
      .every(input => input === true);

    return privacyChecked && allInputsValid;
  };

  render() {
    const {
      privacyChecked,
      submissionSuccess,
      formConfig,
      formSubmitted,
    } = this.state;
    const formIsValid = this.formIsValid();
    const FormFieldMarkup = [
      ...formFields.map(field => {
        const formItem = formConfig[field];
        return (
          <RelativeParent key={field}>
            <CSInputLabel htmlFor={field}>{formItem.placeholder}</CSInputLabel>
            {formItem.formObject === 'input' ? (
              <RelativeParent>
                <CSInputField
                  key={field}
                  id={field}
                  invalidInput={formItem.invalidInput}
                  onChange={e => this.handleInputChange(e, field)}
                  onBlur={e => this.handleInputBlur(e, field)}
                  value={formItem.value}
                />
                {formItem.invalidInput && (
                  <FieldError>
                    {formItem.value.length < 1
                      ? `${formItem.fieldTitle} får ej vara tomt`
                      : 'Vänligen ange en giltig emailadress'}
                  </FieldError>
                )}
              </RelativeParent>
            ) : formItem.formObject === 'textarea' ? (
              <CSTextArea
                key={field}
                id={field}
                onChange={e => this.handleInputChange(e, field)}
                onBlur={e => this.handleInputBlur(e, field)}
                value={formItem.value}
              />
            ) : null}
          </RelativeParent>
        );
      }),
      <PrivacyTOC>
        <CSCheckbox
          id="test-id-toc"
          isChecked={privacyChecked}
          onClick={this.handleCheckboxClick}
        />
        <CheckboxText>
          Jag har läst och godkänner att min personliga information behandlas
          enligt MCON AB’s{' '}
          <Link to={navConfig.privacy.url} id="test-id-privacy-link">
            <TOC>integritetspolicy.</TOC>
          </Link>
        </CheckboxText>
      </PrivacyTOC>,
      <CSSubmitButtonWrapper>
        <Button
          onClick={this.handleFormSubmit}
          disabled={!formIsValid}
          id="test-id-form-submit"
          bold
        >
          Skicka
        </Button>
        {formSubmitted && !submissionSuccess ? (
          <FadeRight physics="default">
            <FormSubmissionError>
              Hmm.. något gick fel.
              <br />
              Prova igen.
            </FormSubmissionError>
          </FadeRight>
        ) : null}
      </CSSubmitButtonWrapper>,
    ];

    return (
      <FormWrapper>
        {formSubmitted && submissionSuccess ? (
          <FadeUp delay={400}>
            <SuccessMessage>
              <FadeUp delay={450}>
                <SuccessHeader>Tack för din förfrågan!</SuccessHeader>
                <SuccessBody>
                  Du kommer att bli kontaktad av en av våra konsulter som är
                  expert på att assistera vid just ditt ärende.
                </SuccessBody>
              </FadeUp>
            </SuccessMessage>
          </FadeUp>
        ) : (
          <React.Fragment>
            <FormFieldsTrail
              native
              config={config.default}
              keys={FormFieldMarkup.map((_, i) => i)}
              items={FormFieldMarkup}
            >
              {(item, i) => ({ opacity, x }) => {
                return (
                  <animated.div
                    style={{
                      transform: x.interpolate(
                        x => `translate3d(${x}px, 0, 0)`
                      ),
                      opacity,
                    }}
                  >
                    {item}
                  </animated.div>
                );
              }}
            </FormFieldsTrail>
          </React.Fragment>
        )}
      </FormWrapper>
    );
  }
}

// @flow
import styled from 'styled-components';

import { media } from '../../utils/style-utils';
import { hex2rgba } from '../../utils/general';

export const RelativeParent = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
`;

export const FormWrapper = styled.div`
  z-index: 9;
  position: relative;
  margin: 32px 0 0 0;

  ${media.phone`
    margin: 32px auto 0 0;
  `};

  ${media.tablet`
  max-width: 430px;
  margin: 20px auto 0 0;
`};

  ${media.hd`
  max-width: 450px;
  margin: 40px auto 0 0;
`};
`;

export const BaseInputStyles = styled.div`
  width: 100%;
  padding: 24px 16px 16px 16px;
  background: linear-gradient(
    to right,
    ${hex2rgba('#0e3795', 0.5)},
    ${hex2rgba('#07206a', 0.6)}
  );
  margin-bottom: 16px;
  border: ${props => (props.invalidInput ? '2px solid #D0021B' : 'none')};
  font-size: 14px;
  font-weight: 300;
  color: #fff;
  outline: none;

  ${media.tablet`
  font-size: 18px;
`};

  ${media.hd`
  font-size: 20px;
  margin-bottom: 22px;
`};
`;

export const CSInputField = styled(BaseInputStyles.withComponent('input'))`
  &:focus {
    outline: none;
    box-shadow: 0px 0px 7px 2px ${hex2rgba('#FFFFFF', 0.3)};
  }

  min-height: 48px;
  color: #fff !important;
`;
export const CSTextArea = styled(BaseInputStyles.withComponent('textarea'))`
  min-height: 115px;
  resize: none;
  overflow: auto;
`;
export const CSInputLabel = styled.p`
  font-size: 10px;
  color: #fff;
  opacity: 0.6;
  position: absolute;
  top: 6px;
  left: 16px;
  z-index: 2;

  ${media.tablet`
  font-size: 11px;
`};

  ${media.hd`
  font-size: 11px;
`};
`;

export const PrivacyTOC = styled.div`
  display: flex;
  flex-wrap: no-wrap;
  align-items: center;
`;

export const CSCheckbox = styled.div`
  width: 20px;
  height: 20px;
  background-image: ${props =>
    props.isChecked
      ? 'url(./Checkbox/Checked.svg)'
      : 'url(./Checkbox/Default.svg)'};
  background-repeat: no-repeat;
  background-size: ${props => (props.isChecked ? '150%' : 'contain')};
  background-position: center center;
  margin-right: 12px;
  cursor: pointer;
`;

export const CheckboxText = styled.p`
  font-size: 12px;
  flex-basis: calc(100% - 32px);
  text-align: left;
  font-weight: 300;

  ${media.tablet`
  font-size: 14px;
`};
`;

export const TOC = styled.span`
  font-weight: 500;
  border-bottom: 1px solid #58a0e7;
  cursor: pointer;
`;

export const CSSubmitButtonWrapper = styled.div`
  margin-top: 32px;
  display: flex;
  justify-content: flex-start;
`;

export const SuccessMessage = styled.div`
  background: linear-gradient(
    to right,
    ${hex2rgba('#0e3795', 0.5)},
    ${hex2rgba('#07206a', 0.6)}
  );
  padding: 24px;
  width: 100%;
  color: #fff;
  min-height: 285px;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const SuccessHeader = styled.h5`
  flex-basis: 100%;
  font-size: 24px;
  margin-bottom: 32px;

  ${media.tablet`
  font-size: 28px;
`};

  ${media.hd`
  font-size: 32px;
`};
`;
export const SuccessBody = styled.p`
  flex-basis: 100%;
  font-size: 14px;

  ${media.tablet`
  font-size: 16px;
`};

  ${media.hd`
  font-size: 20px;
`};
`;

export const FormSubmissionError = styled.div`
  margin-left: 22px;
  font-size: 14px;
  font-weight: 900;

  ${media.tablet`
    font-size: 16px;
  `};

  ${media.hd`
    font-size: 20px;
  `};
`;

export const FieldError = styled.p`
  position: absolute;
  top: 4px;
  right: 4px;
  background: rgba(208, 2, 27, 0.5);
  color: #fff;
  padding: 4px 8px;
  border-radius: 10px;
  font-family: Quicksand, sans-serif;
  font-size: 12px;
`;
